import moment from 'moment';
import Moment from 'moment-timezone';
import * as constants from './const';

export function setDocumentTitle(title) {
  document.title = `${process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : ''} ${title || process.env.REACT_APP_DOC_TITLE}`;
}

export function utcToLocalDateFormat(utcTime) {
  return utcTime ? moment.utc(utcTime).local().format(constants.DATE_FORMAT) : null;
}

export function utcToLocalDateTimeFormat(utcTime) {
  return utcTime ? moment.utc(utcTime).local().format(constants.DATETIME_FORMAT) : null;
}
export function utcToBrisbaneDateFormatForEdit(utcTime) {
  return utcTime ? Moment(utcTime).tz(constants.SYSTEM_TIMEZONE).format(constants.DATETIME_FORMAT) : null;
}
export function utcToBrisbaneDateFormat(utcTime) {
  return utcTime ? Moment(utcTime).tz(constants.SYSTEM_TIMEZONE).format(constants.DATE_FORMAT) : null;
}
export function getResponseErrorDetais(errors) {
  const messages = [];

  if (typeof errors === 'object') {
    if (errors.errors) {
      for (const property in errors.errors) {
        if (Object.prototype.hasOwnProperty.call(errors.errors, property)) {
          const vmErrors = errors.errors[property];
          if (vmErrors && Array.isArray(vmErrors)) {
            vmErrors.forEach((message) => {
              messages.push(message);
            });
          }
        }
      }
    }

    const excludedAttributes = [constants.EXCLUDED_ERROR_TRACE_ID, constants.EXCLUDED_ERROR_TYPE];

    Object.keys(errors).forEach((attribute) => {
      if (!excludedAttributes.includes(attribute)) {
        const attributeMessages = errors[attribute];
        if (Array.isArray(attributeMessages)) {
          attributeMessages.forEach((message) => {
            messages.push(message);
          });
        } else if (typeof attributeMessages === 'string') {
          messages.push(attributeMessages);
        }
      }
    });
  } else {
    messages.push(errors);
  }

  return messages;
}

export function getResponseErrorMessages(error) {
  if (error) {
    if (error.response && error.response.data && typeof error.response.data === 'object') {
      return getResponseErrorDetais(error.response.data);
    }
    if (error.validation && error.validation.errorMessages) {
      return error.validation.errorMessages;
    }
    if (error.data && error.data.validation && error.data.validation.errorMessages) {
      return error.data.validation.errorMessages;
    }
    if (error.response && error.response.data && typeof error.response.data === 'string') {
      return [error.response.data];
    }
    if (error.response && error.response.status === 401) {
      return ['You are not authorized (401) to perform this operation.'];
    }
    if (error.response && error.response.status === 403) {
      return ['You are Forbidden (403) to perform this operation.'];
    }
    if (error.message) {
      return [error.message];
    }
    if (typeof error === 'string') {
      return [error];
    }
  }

  return ['Something went wrong.'];
}

export function sortVocabsByDisplayText(referenceDataList) {
  referenceDataList.sort((x, y) => {
    const a = x.label.toUpperCase();
    const b = y.label.toUpperCase();
    if (a === b) {
      return 0;
    }
    if (a > b) {
      return 1;
    }
    return -1;

    // return a == b ? 0 : a > b ? 1 : -1;
  });
  return referenceDataList;
}
export function getValueForSelectedItem(referenceDataList, selectedValue) {
  if (selectedValue) {
    if (referenceDataList && referenceDataList.length > 0) {
      const selectedItem = referenceDataList.find((x) => x.label === selectedValue.trim());
      if (selectedItem) {
        return selectedItem.value;
      }
    }
  }
  return '-';
}
export function getFilterValueForSelectedItem(referenceDataList, selectedValue) {
  if (selectedValue) {
    if (referenceDataList && referenceDataList.length > 0) {
      const selectedItem = referenceDataList.find((x) => x.value === selectedValue.trim());
      if (selectedItem) {
        return selectedItem.filterByChildCollection;
      }
    }
  }
  return '-';
}
export function getValueForDisplayTextArray(referenceDataList, displayTextArray) {
  const values = [];
  if (displayTextArray && referenceDataList) {
    displayTextArray.forEach((v) => {
      const label = getValueForSelectedItem(referenceDataList, v);
      if (!values.includes(label)) {
        values.push(label);
      }
    });
  }
  if (values.length) {
    return values.sort().join(', ');
  }
  return '-';
}

export function getDisplayTextForValue(referenceDataList, selectedValue) {
  if (selectedValue) {
    if (referenceDataList && referenceDataList.length > 0) {
      const selectedItem = referenceDataList.find((x) => x.value === selectedValue.trim());
      if (selectedItem) {
        return selectedItem.label;
      }
    }
  }
  return '-';
}

export function getDisplayTextForValueArray(referenceDataList, valueArray) {
  const text = [];
  if (valueArray && referenceDataList) {
    valueArray.forEach((v) => {
      const label = getDisplayTextForValue(referenceDataList, v);
      if (!text.includes(label)) {
        text.push(label);
      }
    });
  }
  if (text.length) {
    return text.sort().join(', ');
  }
  return '-';
}

export function getUserGroups(groups) {
  const userGroups = [];
  if (groups) {
    const groupsSplit = groups.split(',');
    if (groupsSplit) {
      groupsSplit.forEach((group) => {
        let userGroup = group.trim();
        userGroup = userGroup.replace('[', '');
        userGroup = userGroup.replace(']', '');
        userGroup = userGroup.replaceAll('"', '');
        userGroups.push(userGroup);
      });
    }
  }
  return userGroups;
}

export function getDistinctFromArray(array) {
  return Array.from(new Set(array));
}

export function isFormDirty(form) {
  let isDirty = false;
  if (form.dirty && form.submitCount === 0) {
    isDirty = true;
  }
  return (
    isDirty
  );
}

export function isRequired(input) {
  let result = false;
  if (input && input.validations && input.validations.rules && input.validations.rules.length > 0) {
    const requiredRule = input.validations.rules.filter((x) => x.name === 'required');
    result = !!(requiredRule && requiredRule.length > 0);
  }

  return result;
}

export function getMinMaxValue(input, ruleName) {
  let value = null;
  const { validations } = input;
  if (validations) {
    const { rules } = validations;
    if (rules && rules.length > 0) {
      const rule = rules.find((r) => r.name === ruleName);
      if (rule) {
        const { params } = rule;
        if (params && params.length > 0) {
          [value] = params;
        }
      }
    }
  }
  return value;
}

export function parseS3Url(s3Url) {
  // Process all aws s3 url cases
  const url = decodeURIComponent(s3Url);
  let match = '';

  // http://s3.amazonaws.com/bucket/key1/key2
  match = url.match(/^https?:\/\/s3.amazonaws.com\/([^/]+)\/?(.*?)$/);
  if (match) {
    return {
      bucket: match[1],
      key: match[2],
      region: '',
    };
  }

  // http://s3-aws-region.amazonaws.com/bucket/key1/key2
  match = url.match(/^https?:\/\/s3-([^.]+).amazonaws.com\/([^/]+)\/?(.*?)$/);
  if (match) {
    return {
      bucket: match[2],
      key: match[3],
      region: match[1],
    };
  }

  // http://bucket.s3.amazonaws.com/key1/key2
  match = url.match(/^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/);
  if (match) {
    return {
      bucket: match[1],
      key: match[2],
      region: '',
    };
  }

  // http://bucket.s3-aws-region.amazonaws.com/key1/key2
  match = url.match(/^https?:\/\/([^.]+).s3-([^.]+).amazonaws.com\/?(.*?)$/);
  if (match) {
    return {
      bucket: match[1],
      key: match[3],
      region: match[2],
    };
  }

  // http://bucket.s3.aws-region.amazonaws.com/key1/key2
  match = url.match(/^https?:\/\/([^.]+).s3.([^.]+).amazonaws.com\/?(.*?)$/);
  if (match) {
    return {
      bucket: match[1],
      key: match[3],
      region: match[2],
    };
  }

  return {
    bucket: '',
    key: '',
    region: '',
  };
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

export function getFileExtenstion(filename) {
  return (filename.lastIndexOf('.') < 1) ? null : filename.split('.').slice(-1).pop();
}

export function toUpperCaseKeys(obj) {
  const element = {};
  return Object.keys(obj).reduce((accum, key) => {
    element[key.toUpperCase()] = obj[key];
    return element;
  }, {});
}

export function utcTimeHasElapsed(datetime) {
  const utc = new Date(datetime);
  const now = new Date();
  const nowUtc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

  return utc.getTime() < nowUtc.getTime();
}

export function timeHasElapsed(datetime) {
  const date = new Date(datetime);
  const now = new Date();

  return date.getTime() < now.getTime();
}

export function arrayHasNoValue(arrayWithValue) {
  return arrayWithValue === undefined
    || arrayWithValue === null
    || arrayWithValue === ''
    || JSON.stringify(arrayWithValue) === JSON.stringify([])
    || arrayWithValue.length === null
    || arrayWithValue.length === 0;
}

export function toTitleCase(text) {
  if (typeof text !== 'string') return '';

  let titleCase = '';

  const textSplit = text.split(' ');
  textSplit.forEach((t) => {
    if (t) {
      if (t.length > 1) {
        titleCase += t.charAt(0).toUpperCase() + t.slice(1).toLowerCase();
      } else {
        titleCase += t.charAt(0).toUpperCase();
      }
      titleCase += ' ';
    }
  });

  titleCase = titleCase.trim();

  return titleCase;
}

export function capitalize(text) {
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export function isEndOfQuarter(startDate, endDate) {
  let result = false;
  const endDateFormatted = moment(endDate).format(constants.DATE_FORMAT);
  const lastDateOFQuarter = moment(startDate).add(2, 'M').endOf('month').format(constants.DATE_FORMAT);
  // const lastDateOFQuarter = moment().quarter(moment(startDate, constants.DATE_FORMAT).quarter()).endOf('quarter').format(constants.DATE_FORMAT);
  if (lastDateOFQuarter === endDateFormatted) {
    result = true;
  }
  return result;
}

export function isEndOFFinancialYear(endDate) {
  let result = false;
  const endDateFormatted = moment(endDate).format(constants.DATE_FORMAT);
  if (endDateFormatted.startsWith('30 Jun')) {
    result = true;
  }
  return result;
}

export function validateEndOfQuarter(rule, values) {
  //  implement here
  let error = false;
  if (rule) {
    const { startDate, endDate } = values;
    const { params } = rule;
    if (startDate && endDate) {
      const isLastDayOfTheQuarter = isEndOfQuarter(startDate, endDate);
      if (!isLastDayOfTheQuarter) {
        error = params && params.length > 0 ? params[0] : 'Please select the end date of the quarter';
      }
    }
  }
  return error;
}

export function isStartOfMonth(startDate) {
  let result = false;
  const startDateFormatted = moment(startDate).format(constants.DATE_FORMAT);
  const startDateOfMonth = moment(startDate).startOf('month').format(constants.DATE_FORMAT);
  if (startDateFormatted === startDateOfMonth) {
    result = true;
  }
  return result;
}

export function validateEndOfFinancialYear(rule, values) {
  //  implement here
  let error = false;
  if (rule) {
    const { endDate } = values;
    const { params } = rule;
    if (endDate) {
      const isLastDayOfTheFinancialYear = isEndOFFinancialYear(endDate);
      if (!isLastDayOfTheFinancialYear) {
        error = params && params.length > 0 ? params[0] : 'Please select the end date of the financial Year';
      }
    }
  }
  return error;
}

export function validateStartOfMonth(rule, values) {
  let error = false;
  if (rule) {
    const { startDate } = values;
    const { params } = rule;
    if (startDate) {
      const isStartDateOfMonth = isStartOfMonth(startDate);
      if (!isStartDateOfMonth) {
        error = params && params.length > 0 ? params[0] : 'Please select the first day of month';
      }
    }
  }
  return error;
}

export function formatThousandGrouping(value) {
  if (value || value === 0) {
    return parseFloat(value).toLocaleString('en-US');
  }
  return '-';
}

export function isEndOfFinancialYear(endDate) {
  let result = false;
  const endDateFormatted = moment(endDate).format(constants.DATE_FORMAT);
  if (endDateFormatted.startsWith('30 Jun')) {
    result = true;
  }
  return result;
}
export function changeTimezone(date) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(date.toLocaleString('en-US', {
    timeZone: 'Australia/Brisbane',
  }));

  // then invdate will be XX:X0 in Brisbane
  // and the diff is X hours
  const diff = invdate.getTime() - date.getTime();

  // so 12:00 in Brisbane is 12:30 UTC
  return new Date(date.getTime() + diff); // needs to substract
}

export function dateWithTimeZone(dataValue) {
  let date = null;
  if (dataValue) {
    date = new Date(dataValue);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: constants.SYSTEM_TIMEZONE }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);
  }
  return date;
}
