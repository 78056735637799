import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import preloadedState from '../reducers/preloadedState';
import { combinedReducers } from '../reducers/combinedReducers';

export const history = createBrowserHistory();

const reducers = combinedReducers(history);

// to use redux dev tools or just the default compose if no __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ exists
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  preloadedState,
  composeEnhancers(
    applyMiddleware(
      thunk,
    ),
  ),
);
