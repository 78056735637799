import PreloadedState from './preloadedState';
import * as types from '../actionTypes/report';
import {
  DOCUMENT_TYPE_ASSOCIATED_DOCUMENT, DOCUMENT_TYPE_REPORT_GUIDELINE_TEMPLATE, DOCUMENT_TYPE_SPATIAL_FILE, DOCUMENT_TYPE_STATISTICAL_REPORTING_DOCUMENT,
} from '../js/const';

const reportReducer = (state = PreloadedState.report, action) => {
  switch (action.type) {
    case types.REPORT_SET_SEARCH_RESULT:
      return {
        ...state,
        searchResults: action.data ? action.data.data : null,
        searchTotalRecords: action.data ? action.data.total : null,
      };
    case types.REPORT_SET_QUERY_VALUES:
      return {
        ...state,
        queryValues: action.data,
      };
    case types.REPORT_SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case types.REPORT_SET_TYPE:
      return {
        ...state,
        reportType: action.reportType,
      };
    case types.REPORT_SET_DECLARATION:
      return {
        ...state,
        declaration: action.declaration,
      };
    case types.REPORT_SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case types.REPORT_SET_TITLE:
      return {
        ...state,
        data: {
          ...state.data,
          reportTitle: action.data,
        },
      };
    case types.REPORT_CLEAR_DATA:
      return {
        ...state,
        data: {
          ...PreloadedState.report.data,
        },
        reportType: null,
      };
    case types.REPORT_SET_ASSOCIATED_DOCUMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          associatedDocuments: action.data,
        },
      };
    case types.REPORT_SET_REPORTING_GUIDELINE_DOCUMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          reportingTemplateDocuments: action.data,
        },
      };
    case types.REPORT_SET_SPATIAL_DOCUMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          spatialDocuments: action.data,
        },
      };
    case types.REPORT_SET_STATISTICAL_GUIDELINE_DOCUMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          statisticalReportingDocuments: action.data,
        },
      };
    }
    case types.REPORT_SET_DOCUMENT_VALIDATION_ERRORS: {
      const { documentType, errors } = { ...action.data };

      // find current error message for type of document
      let errorsState = { ...state.data.documentValidationErrors };

      let errorsForDocType = [];

      switch (documentType) {
        case DOCUMENT_TYPE_ASSOCIATED_DOCUMENT: {
          errorsForDocType = [...errorsState.associatedDocuments];
          break;
        }
        case DOCUMENT_TYPE_REPORT_GUIDELINE_TEMPLATE:
          errorsForDocType = [...errorsState.reportingTemplateDocuments];
          break;
        case DOCUMENT_TYPE_STATISTICAL_REPORTING_DOCUMENT:
          errorsForDocType = [...errorsState.statisticalReportingDocuments];
          break;
        case DOCUMENT_TYPE_SPATIAL_FILE:
          errorsForDocType = [...errorsState.spatialDocuments];
          break;
        default:
          break;
      }

      if (errors === undefined || errors === null) {
        // clear errors of all files that are not in the state for this type
        errorsForDocType = [];
      } else {
        // remove all error messages for files in current action
        const filesWithErrors = [...new Set(errors.map((e) => e.fileName))];
        const errorsForOtherFiles = [];

        filesWithErrors.forEach((fileName) => {
          errorsForOtherFiles.concat(errorsForDocType.filter((f) => f.fileName !== fileName));
        });

        // only add errors that have a message with them
        const newErrorsToAddForDocType = errors.filter((e) => e.message !== undefined && e.message !== null);
        errorsForDocType = [...errorsForOtherFiles].concat(newErrorsToAddForDocType);
      }

      // start making new state object
      switch (documentType) {
        case DOCUMENT_TYPE_ASSOCIATED_DOCUMENT: {
          errorsState = {
            ...errorsState,
            associatedDocuments: [...errorsForDocType],
          };
          break;
        }
        case DOCUMENT_TYPE_REPORT_GUIDELINE_TEMPLATE:
          errorsState = {
            ...errorsState,
            reportingTemplateDocuments: [...errorsForDocType],
          };
          break;
        case DOCUMENT_TYPE_STATISTICAL_REPORTING_DOCUMENT:
          errorsState = {
            ...errorsState,
            statisticalReportingDocuments: [...errorsForDocType],
          };
          break;
        case DOCUMENT_TYPE_SPATIAL_FILE:
          errorsState = {
            ...errorsState,
            spatialDocuments: [...errorsForDocType],
          };
          break;
        default:
          break;
      }

      // update state
      return {
        ...state,
        data: {
          ...state.data,
          documentValidationErrors: errorsState,
        },
      };
    }
    case types.REPORT_DELETE_REPORT_DATA: {
      const searchResults = state.searchResults ? [...state.searchResults] : null;
      const searchTotalRecords = state.searchTotalRecords ? state.searchTotalRecords - 1 : null;
      if (searchResults && searchResults.length > 0) {
        const objIndex = searchResults.findIndex(((obj) => obj.reportId === action.data));
        searchResults.splice(objIndex, 1);
      }
      return {
        ...state,
        searchResults,
        searchTotalRecords,
      };
    }
    case types.REPORT_DELETE_WEB_FORM_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          webForms: PreloadedState.report.data.webForms,
        },
      };
    }
    default:
      return state;
  }
};

export default reportReducer;
