export const REPORT_CLEAR_DATA = 'REPORT_CLEAR_DATA';
export const REPORT_DELETE_REPORT_DATA = 'REPORT_DELETE_REPORT_DATA';
export const REPORT_SET_ASSOCIATED_DOCUMENTS = 'REPORT_SET_ASSOCIATED_DOCUMENTS';
export const REPORT_SET_DATA = 'REPORT_SET_DATA';
export const REPORT_SET_DOCUMENT_VALIDATION_ERRORS = 'REPORT_SET_DOCUMENT_VALIDATION_ERRORS';
export const REPORT_SET_PAGE_SIZE = 'REPORT_SET_PAGE_SIZE';
export const REPORT_SET_QUERY_VALUES = 'REPORT_SET_QUERY_VALUES';
export const REPORT_SET_REPORTING_GUIDELINE_DOCUMENTS = 'REPORT_SET_REPORTING_GUIDELINE_DOCUMENTS';
export const REPORT_SET_SEARCH_RESULT = 'REPORT_SET_SEARCH_RESULT';
export const REPORT_SET_SPATIAL_DOCUMENTS = 'REPORT_SET_SPATIAL_DOCUMENTS';
export const REPORT_SET_STATISTICAL_GUIDELINE_DOCUMENTS = 'REPORT_SET_STATISTICAL_GUIDELINE_DOCUMENTS';
export const REPORT_SET_TITLE = 'REPORT_SET_TITLE';
export const REPORT_SET_TYPE = 'REPORT_SET_TYPE';
export const REPORT_SET_DECLARATION = 'REPORT_SET_DECLARATION';
export const REPORT_DELETE_WEB_FORM_DATA = 'REPORT_DELETE_WEB_FORM_DATA';
