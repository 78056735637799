import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import LoadingSpinner from './loadingSpinner';

const LoadingDisplay = ({ app }) => (app.isLoading
  && (
    <LoadingSpinner />
  )
);

LoadingDisplay.propTypes = {
  app: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(LoadingDisplay);
