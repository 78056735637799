import PreloadedState from './preloadedState';
import * as types from '../actionTypes/user';
import { Groups } from '../js/accessRules';

const userReducer = (state = PreloadedState.user, action) => {
  switch (action.type) {
    case types.SET_USER: {
      const groups = [...action.user.groups || []];
      const isPrivateUser = groups && groups.length > 0;
      const canCreate = isPrivateUser ? !!(groups.find((x) => x === Groups.CREATE_GROUP)) : true;
      const canUpdate = isPrivateUser ? !!(groups.find((x) => x === Groups.UPDATE_GROUP)) : true;
      const canDelete = isPrivateUser ? !!(groups.find((x) => x === Groups.DELETE_GROUP)) : false;
      const canRead = isPrivateUser ? !!(groups.find((x) => x === Groups.READ_GROUP)) || canCreate || canUpdate || canDelete : true;

      return {
        ...state,
        isAuthenticated: action.user.isAuthenticated,
        userName: action.user.userName,
        sub: action.user.sub,
        givenName: action.user.givenName,
        familyName: action.user.familyName,
        email: action.user.email,
        groups,
        canCreate,
        canRead,
        canUpdate,
        canDelete,
        isPrivateUser,
      };
    }
    case types.CLEAR_USER: {
      return PreloadedState.user;
    }
    default:
      return state;
  }
};

export default userReducer;
