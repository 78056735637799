/**
 * Setup the initial state for redux.
 * Actual hydrated values will replace these values upon application init.
 */
export default {
  app: {
    isDirty: false,
    isLoading: false,
    isMenuVisible: true,
    isHeaderTitleVisible: true,
    error: {
      errorCount: 0,
      errorMessages: null,
      errorHeader: null,
    },
    serverResponse: {},
    quickSearchParams: null,
  },
  user: {
    isAuthenticated: undefined,
    userName: null,
    sub: null,
    givenName: null,
    familyName: null,
    email: null,
    groups: null,
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
    isPrivateUser: false,
  },
  referenceData: {
    vocab: null,
    reportTemplate: null,
    reportDataTemplate: null,
    suggestion: null,
  },
  report: {
    data: {
      survey: [],
      author: [],
      operator: [],
      relatedOperator: [],
      permit: [],
      commodity: [],
      site: [],
      dataCategory: [],
      associatedDocuments: [],
      spatialDocuments: [],
      reportingTemplateDocuments: [],
      statisticalReportingDocuments: [],
      title: null,
      documentValidationErrors: {
        associatedDocuments: [],
        spatialDocuments: [],
        reportingTemplateDocuments: [],
        statisticalReportingDocuments: [],
      },
      reportableData: null,
      reportableDataEntryType: null,
      webForms: {
        productTransfers: {
          hasDataForReportPeriod: null,
          items: [],
        },
        mineDetails: {
          daysOfOperation: null,
          wasteRock: null,
          wasteUnits: null,
          freightCost: null,
        },
        sales: {
          hasDataForReportPeriod: null,
          items: [],
        },
        rawMaterialTransferred: {
          hasDataForReportPeriod: null,
          items: [],
        },
        rawCommodityStockpile: {
          hasDataForReportPeriod: null,
          items: [],
        },
        coalRawCoalMined: {
          hasDataForReportPeriod: null,
          items: [],
        },
        coalSaleableStockpile: {
          items: [],
        },
        productsProcessed: {
          hasDataForReportPeriod: null,
          items: [],
        },
        rawMaterialMined: {
          items: [],
        },
        operationalDetails: {
          items: [],
        },
        extractiveSales: {
          items: [],
        },
        quarryDetails: {
          fteEquivalentEmployees: null,
          fteEquivalentContractors: null,
          reportingPeriodSales: null,
        },

      },
    },
    searchTotalRecords: null,
    pageSize: 10,
    reportType: null,
    searchResults: null,
    queryValues: null,
    declaration: false,
  },
};
