import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from './app';
import userReducer from './user';
import reportReducer from './report';
import referenceDataReducer from './referenceData';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  app: appReducer,
  user: userReducer,
  report: reportReducer,
  referenceData: referenceDataReducer,
});

export const combinedReducers = (state, action) => rootReducer(state, action);
