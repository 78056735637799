import * as types from '../actionTypes/app';

export function setDirty() {
  return {
    type: types.SET_DIRTY_STATUS,
  };
}

export function clearDirty() {
  return {
    type: types.CLEAR_DIRTY_STATUS,
  };
}

export function setLoading() {
  return {
    type: types.SET_LOADING_STATUS,
  };
}

export function clearLoading() {
  return {
    type: types.CLEAR_LOADING_STATUS,
  };
}

export function setErrors({ errorMessages, header }) {
  return {
    type: types.SET_ERROR,
    data: {
      errorMessages,
      header,
    },
  };
}

export function clearErrors() {
  return {
    type: types.CLEAR_ERROR,
    errorMessages: null,
  };
}

export function setHeader() {
  return {
    type: types.SET_HEADER,
  };
}

export function clearHeader() {
  return {
    type: types.CLEAR_HEADER,
  };
}

export function setServerResponse(data) {
  return {
    type: types.SET_SERVER_RESPONSE,
    serverResponse: data,
  };
}

export function clearServerResponse() {
  return {
    type: types.CLEAR_SERVER_RESPONSE,
  };
}

export function setQuickSearchParameters(data) {
  return {
    type: types.SET_QUICK_SEARCH_PARMATERS,
    quickSearchParams: data,
  };
}
