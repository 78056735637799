import { getDistinctFromArray } from './utils';

export const Groups = {
  CREATE_GROUP: `${process.env.REACT_APP_CREATE_GROUP}`,
  READ_GROUP: `${process.env.REACT_APP_READ_GROUP}`,
  UPDATE_GROUP: `${process.env.REACT_APP_UPDATE_GROUP}`,
  DELETE_GROUP: `${process.env.REACT_APP_DELETE_GROUP}`,
};

export const GroupsArray = () => {
  const groups = [];
  Object.keys(Groups).forEach((g) => {
    groups.push(Groups[g]);
  });
  return getDistinctFromArray(groups).join(',');
};

export const Actions = {
  CREATE: 'Create',
  READ: 'Read',
  UPDATE: 'Update',
  DELETE: 'Delete',
};
