import React from 'react';

import '../../css/loading-display.css';

const Spinner = require('../../images/loading-spinner.svg');

const LoadingSpinner = () => (
  <div
    className="progress-spinner-backdrop"
  >
    <div>
      <img
        alt="Loading"
        className="loading-spinner"
        src={Spinner}
      />

      <div className="loading-heading">Working, working, working…</div>
      <div className="loading-sub">This should only take a few moments</div>
    </div>
  </div>
);

export default LoadingSpinner;
