import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import RenderInBrowser from 'react-render-in-browser';
import { AWSConfig, requireSignIn } from '../js/auth';
import { axiosSetup } from '../js/axios';
import { history, store } from '../js/store';
import LoadingDisplay from '../components/base/loadingDisplay';

import '../css/app.css';

const AppContent = React.lazy(() => import('./common/appContent'));
const BrowserSupportContent = React.lazy(() => import('./common/browserSupportContent'));

if (requireSignIn) Amplify.configure(AWSConfig);

/*
set up axios global object so we can have interceptors to do things with every request
like adding an authorisation token or handling errors
*/
axiosSetup();

const App = () => (
  <div
    id="layout-content"
    className="layout-wrapper"
  >
    <React.Suspense
      fallback={<LoadingDisplay />}
    >
      <RenderInBrowser ie only>
        <BrowserSupportContent history={history} />
      </RenderInBrowser>

      <RenderInBrowser except ie>
        <AppContent history={history} />
      </RenderInBrowser>
    </React.Suspense>
  </div>
);

const AppWithProvider = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

export default AppWithProvider;
