export const SET_DIRTY_STATUS = 'SET_DIRTY_STATUS';
export const CLEAR_DIRTY_STATUS = 'CLEAR_DIRTY_STATUS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const CLEAR_LOADING_STATUS = 'CLEAR_LOADING_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const SET_SERVER_RESPONSE = 'SET_SERVER_RESPONSE';
export const CLEAR_SERVER_RESPONSE = 'CLEAR_SERVER_RESPONSE';
export const SET_QUICK_SEARCH_PARMATERS = 'SET_QUICK_SEARCH_PARMATERS';
export const SET_HEADER = 'SET_HEADER';
export const CLEAR_HEADER = 'CLEAR_HEADER';
