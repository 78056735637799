import Amplify, { Auth, Hub } from 'aws-amplify';
import { getResponseErrorMessages, utcTimeHasElapsed } from './utils';
import { history, store } from './store';
import { setServerResponse } from '../actions/app';
import { CLEAR_USER } from '../actionTypes/user';

const ACTIVE_LOGIN = 'activelogin';
const ACTIVE_LOGIN_IN_PROGRESS = 'inProgress';

Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_AMPLIFY_LOGGER_LEVEL;

export const requireSignIn = process.env.REACT_APP_COGNITO_REQUIRE_SIGN_IN === 'true';

// Retrieve Current Authenticated User
export const currentAuthenticatedUser = () => new Promise((resolve, reject) => {
  Auth.currentAuthenticatedUser({
    // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    bypassCache: false,
  }).then((data) => resolve(data))
    .catch((error) => reject(error));
});

// Retrieve Authenticated Credentials of Current User.
export const currentUserCredentials = () => new Promise((resolve, reject) => {
  Auth.currentUserCredentials()
    .then((data) => resolve(data))
    .catch((error) => reject(error));
});

// Retrieve Authenticated Credentials.
export const currentCredentials = () => new Promise((resolve, reject) => {
  Auth.currentCredentials()
    .then((data) => {
      if (data.expired || utcTimeHasElapsed(data.expireTime)) {
        resolve(currentUserCredentials());
      } else {
        resolve(data);
      }
    })
    .catch((error) => reject(error));
});

export const currentSession = () => new Promise((resolve, reject) => {
  Auth.currentSession()
    .then((data) => resolve(data))
    .catch((error) => reject(error));
});

export const getUserToken = () => new Promise((resolve, reject) => {
  currentSession()
    .then((data) => {
      resolve(data.getIdToken().getJwtToken());
    })
    .catch((error) => {
      reject(error);
    });
});

export const OAuthConfig = {
  // Domain name
  domain: process.env.REACT_APP_COGNITO_DOMAIN,

  // Authorized scopes
  scope: process.env.REACT_APP_COGNITO_SCOPE.split(','),

  // Callback URL
  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,

  // Sign out URL
  redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  // Note that REFRESH token will only be generated when the responseType is code
  responseType: process.env.REACT_APP_COGNITO_RESPONSE_TYPE,
};

export const AWSConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: requireSignIn,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',

    // OPTIONAL - Hosted UI configuration
    oauth: { ...OAuthConfig },
  },
};

const onHubCapsule = (capsule) => {
  // The Auth module will emit events when user signs in, signs out, etc
  const { channel, payload } = capsule;
  if (channel === 'auth') {
    switch (payload.event) {
      case 'signIn': // fires for both federated and native
      case 'cognitoHostedUI': // fires only for federated
        window.location.assign(window.location.origin);
        break;
      case 'signUp':
        // user signed up
        break;
      case 'signOut':
        // fires for both federated and native
        break;
      case 'signIn_failure': // fires for native
      case 'cognitoHostedUI_failure': // fires for federated
        break;
      case 'configured':
        // the Auth module is configured'
        break;
      default:
        break;
    }
  }
};

export const signIn = () => {
  const activelogin = sessionStorage.getItem(ACTIVE_LOGIN);
  if (activelogin === ACTIVE_LOGIN_IN_PROGRESS) {
    // Redirect from ADFS login.
    sessionStorage.removeItem(ACTIVE_LOGIN);

    Hub.listen('auth', onHubCapsule);
  } else if (activelogin === null) {
    // First page visit. Redirect to ADFS login.
    // sessionStorage.setItem(ACTIVE_LOGIN, ACTIVE_LOGIN_IN_PROGRESS);
  } else {
    // Credentials exist, page refresh, etc.
  }
};

export const signOut = () => new Promise((resolve, reject) => {
  Auth.signOut()
    .then((data) => resolve(data))
    .catch((error) => reject(error));
});

export const clearStoreAndAuthToken = () => {
  try {
    store.dispatch({
      type: CLEAR_USER,
    });
  } catch (error) {
    setServerResponse({
      data: {
        Success: false,
        ErrorMessages: getResponseErrorMessages(error),
      },
    });
  }
};

export const resetStoreAndLogin = () => {
  history.push('/');
  clearStoreAndAuthToken();
};
