import PreloadedState from './preloadedState';
import * as types from '../actionTypes/referenceData';

const referenceDataReducer = (state = PreloadedState.referenceData, action) => {
  switch (action.type) {
    case types.REFERENCE_DATA_SET_VOCABS:
      return {
        ...state,
        vocab: {
          ...state.vocab,
          ...action.data,
        },
      };
    case types.REFERENCE_DATA_SET_REPORT_TEMPLATE:
      return {
        ...state,
        reportTemplate: action.data,
      };
    case types.REFERENCE_DATA_SET_REPORT_DATA_TEMPLATE:
      return {
        ...state,
        reportDataTemplate: {
          ...state.reportDataTemplate,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default referenceDataReducer;
