import { v4 as uuidv4 } from 'uuid';
import PreloadedState from './preloadedState';
import * as types from '../actionTypes/app';

const appReducer = (state = PreloadedState.app, action) => {
  switch (action.type) {
    case types.SET_DIRTY_STATUS:
      return {
        ...state,
        isDirty: true,
      };
    case types.CLEAR_DIRTY_STATUS:
      return {
        ...state,
        isDirty: false,
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case types.CLEAR_LOADING_STATUS:
      return {
        ...state,
        isLoading: false,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: {
          errorCount: state.error.errorCount + (action.data.errorMessages ? action.data.errorMessages.length : 1),
          errorMessages: action.data.errorMessages,
          errorHeader: action.data.header,
        },
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: {
          errorCount: 0,
          errorMessages: null,
          errorHeader: null,
        },
      };
    case types.TOGGLE_HEADER:
      return {
        ...state,
        isMenuVisible: !state.isMenuVisible,
        isHeaderTitleVisible: !state.isHeaderTitleVisible,
      };
    case types.SET_HEADER:
      return {
        ...state,
        isMenuVisible: true,
        isHeaderTitleVisible: true,
      };
    case types.CLEAR_HEADER:
      return {
        ...state,
        isMenuVisible: false,
        isHeaderTitleVisible: false,
      };
    case types.SET_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: {
          data: {
            id: uuidv4(),
            ...action.serverResponse.data,
          },
        },
      };
    case types.CLEAR_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: {},
      };
    case types.SET_QUICK_SEARCH_PARMATERS:
      return {
        ...state,
        quickSearchParams: action.quickSearchParams,
      };
    default:
      return state;
  }
};

export default appReducer;
