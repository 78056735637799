export const YES = 'YES';
export const NO = 'NO';

export const COGNITO_CLAIM_ADFS_GROUPS = 'custom:Role';
export const DATA_TABLE_MAX_SEARCH_PAGE_SIZE = 10;
export const DATA_TABLE_MAX_ROWS = 10;
export const DATA_TABLE_NO_RECORD_FOUND = 'No records found';
export const DATA_TABLE_SELECTION_MODE_SINGLE = 'single';
export const DATA_TABLE_SELECTION_MODE_MULTIPLE = 'multiple';
export const CALENDAR_SELECTION_MODE_RANGE = 'range';
export const CALENDAR_SELECTION_MODE_MULTIPLE = 'multiple';
export const CALENDAR_DATE_FORMAT = 'dd M yy';

export const MESSAGE_SEVERITY_SUCCESS = 'success';
export const MESSAGE_SEVERITY_INFO = 'info';
export const MESSAGE_SEVERITY_WARN = 'warn';
export const MESSAGE_SEVERITY_ERROR = 'error';

export const DATE_FORMAT = 'DD MMM YYYY';
export const DATETIME_FORMAT = 'DD MMM YYYY HH:MM:SS';
export const SYSTEM_TIMEZONE = 'Australia/Brisbane';

export const CREATE_STATUS_EVENT = 'Created Manually';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_INTENDED = 'Intended';
export const STATUS_PROPOSED = 'Proposed';
export const STATUS_NEVER_DRILLED = 'Never Drilled';
export const STATUS_EVENT_NOTICE_OF_COMPLETION = 'PGGD-02 Submission';

export const MAX_MIGRATED_DATA_ID = 80000;

export const ADD_RECORD = 'ADD';
export const EDIT_RECORD = 'EDIT';
export const DELETE_RECORD = 'DELETE';

export const INDUSTRY_MINERALS = 'Minerals';
export const INDUSTRY_COAL = 'Coal';
export const INDUSTRY_PETROLEUM_GAS = 'Petroleum & Gas'; // not sure if we need one for this, as it is just considered to be everything other than minerals and coal..
export const INDUSTRY_NON = 'Non-Industry';

export const TEXT_PURPOSE = 'purpose';
export const TEXT_STATUS = 'status';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const SITE_BOREHOLE = 'Borehole';

export const OBSERVATION_TYPE_SITE = 'site';
export const OBSERVATION_TYPE_SAMPLE = 'sample';

export const PAGE_SIZE_OPTIONS = [
  { label: '1 rows per page', value: 1 },
  { label: '5 rows per page', value: 5 },
  { label: '10 rows per page', value: 10 },
  { label: '20 rows per page', value: 20 },
  { label: '50 rows per page', value: 50 },
  { label: '100 rows per page', value: 100 },
];

export const REPORT_STEP_METADATA = 'metadata';
export const REPORT_STEP_PRODUCTSTRANSFERRED = 'productstransferred';
export const REPORT_STEP_DOCUMENT = 'document';
export const REPORT_STEP_PERMIT = 'Permits';
export const REPORT_STEP_PRODUCT_PROCESSED = 'productProcessed';
export const REPORT_STEP_RAW_MATERIAL_MINED = 'rawMaterialMined';
export const REPORT_STEP_SALES = 'sales';
export const REPORT_STEP_SUBMIT = 'submit';
export const REPORT_STEP_RAW_COAL_MINED = 'coalRawCoalMined';
export const REPORT_STEP_COAL_SALEABLE_STOCKPILE = 'coalSaleableStockpile';
export const REPORT_STEP_MINERALS_RAW_MATERIAL_TRANSFERRED = 'mineralsRawMaterialTransferred';
export const REPORT_STEP_OPERATIONAL_DETAILS = 'operationalDetails';
export const REPORT_STEP_EXTRACTIVE_SALES = 'extractiveSales';
export const REPORT_STEP_MINERALS_RAW_COMMODITY_STOCKPILE = 'mineralsRawCommodityStockpile';

export const COMPONENT_TYPE_ACCESS_RIGHT = 'accessRight';
export const COMPONENT_TYPE_ALIAS = 'alias';
export const COMPONENT_TYPE_ASSOCIATED_DOCUMENTS = 'associatedDocuments';
export const COMPONENT_TYPE_AUTOCOMPLETE = 'autoComplete';
export const COMPONENT_TYPE_BOREHOLE = 'borehole';
export const COMPONENT_TYPE_BOREHOLE_ALTERATION = 'boreholeAlteration';
export const COMPONENT_TYPE_BOREHOLE_CREATE = 'boreholeCreate';
export const COMPONENT_TYPE_BOREHOLE_FRACTURING = 'boreholeFracturing';
export const COMPONENT_TYPE_BOREHOLE_FRACTURING_COMPLETION = 'boreholeFracturingCompletion';
export const COMPONENT_TYPE_CALENDAR = 'calender';
export const COMPONENT_TYPE_CHECKBOX = 'checkBox';
export const COMPONENT_TYPE_COMMODITY = 'commodity';
export const COMPONENT_TYPE_COMPONENT = 'component';
export const COMPONENT_TYPE_DETAIL = 'detail';
export const COMPONENT_TYPE_DROPDOWN = 'dropdown';
export const COMPONENT_TYPE_EARTH_SCIENCE_DATA = 'earthScienceData';
export const COMPONENT_TYPE_MINE_OPERATOR = 'relatedOperator';
export const COMPONENT_TYPE_MULTISELECT = 'multiSelect';
export const COMPONENT_TYPE_NUMBER = 'number';
export const COMPONENT_TYPE_OPERATOR = 'operator';
export const COMPONENT_TYPE_OWNER = 'owner';
export const COMPONENT_TYPE_PERMIT = 'permit';
export const COMPONENT_TYPE_PERMIT_COAL = 'permitCoal';
export const COMPONENT_TYPE_PERMIT_MINERAL = 'permitminerals';
export const COMPONENT_TYPE_PRODUCT_PROCESSED_COAL = 'productProcessedCoal';
export const COMPONENT_TYPE_PRODUCT_PROCESSED_MINERAL = 'mineralsProductsProcessed';
export const COMPONENT_TYPE_SALES_COAL = 'salesCoal';
export const COMPONENT_TYPE_SALES_MINERAL = 'mineralsSales';
export const COMPONENT_TYPE_PRODUCTTRANSFERRED = 'productTrasferred';
export const COMPONENT_TYPE_RADIOBUTTON = 'radioButton';
export const COMPONENT_TYPE_REPORT_AUTHOR = 'author';
export const COMPONENT_TYPE_REPORT_TITLE = 'title';
export const COMPONENT_TYPE_REPORTABLE_DATA = 'reportableData';
export const COMPONENT_TYPE_REPORTING_TEMPLATE_DOCUMENTS = 'reportingTemplateDocuments';
export const COMPONENT_TYPE_REVIEW = 'review';
export const COMPONENT_TYPE_SELECTGROUP = 'selectGroup';
export const COMPONENT_TYPE_SITE = 'site';
export const COMPONENT_TYPE_SITE_OPERATOR = 'relatedOperator';
export const COMPONENT_TYPE_SPATIAL_DOCUMENTS = 'spatialDocuments';
export const COMPONENT_TYPE_STATISTICAL_REPORTING_DOCUMENTS = 'statisticalReportingDocuments';
export const COMPONENT_TYPE_SURVEY = 'survey';
export const COMPONENT_TYPE_TEXT = 'text';
export const COMPONENT_TYPE_TEXTAREA = 'textArea';
export const COMPONENT_TYPE_RAW_COAL_MINED = 'rawCoalMined';
export const COMPONENT_TYPE_COAL_SALEABLE_STOCKPILE = 'coalSaleableStockpile';
export const COMPONENT_TYPE_MINERAL_RAW_MATERIAL_MINED = 'mineralsRawRaterialMined';
export const COMPONENT_TYPE_MINERAL_DAYS_OF_OPERATION = 'mineralsDaysOfOperation';
export const COMPONENT_TYPE_MINERAL_WASTE_ROCK = 'mineralsWasteRock';
export const COMPONENT_TYPE_RAW_MATERIAL_TRANSFERRED = 'rawMaterialTransferred';
export const COMPONENT_TYPE_OPERATIONAL_DETAILS = 'operationalDetails';
export const COMPONENT_TYPE_EXTRACTIVE_SALES = 'extractiveSales';
export const COMPONENT_TYPE_RAW_COMMODITY_STOCKPILE = 'rawCommodityStockpile';
export const COMPONENT_TYPE_QUARRY_STATUS = 'quarryStatus';
export const COMPONENT_TYPE_EXTRACTIVE_EMPLOYEES = 'extractivesFTEEmployees';
export const COMPONENT_TYPE_EXTRACTIVE_CONTRACTORS = 'extractivesFTEContractors';
export const COMPONENT_TYPE_EXTRACTIVE_REPORTING_PERIOD_SALES = 'extractivesPeriodSales';

export const DOCUMENT_TYPE_REPORT_GUIDELINE_TEMPLATE = 'REPORT_GUIDELINE_TEMPLATE';
export const DOCUMENT_TYPE_ASSOCIATED_DOCUMENT = 'ASSOCIATED_DOCUMENT';
export const DOCUMENT_TYPE_SPATIAL_FILE = 'SPATIAL_FILE';
export const DOCUMENT_TYPE_STATISTICAL_REPORTING_DOCUMENT = 'STATISTICAL_REPORTING_DOCUMENT';

export const DATA_TEMPLATE_COMPONENT_MINE_DETAILS = 'MINE_DETAILS';
export const DATA_TEMPLATE_COMPONENT_PRODUCTION = 'PRODUCTION';
export const DATA_TEMPLATE_COMPONENT_PRODUCTS_PROCESSED = 'PRODUCTS_PROCESSED';
export const DATA_TEMPLATE_COMPONENT_PRODUCTS_TRANSFERRED = 'PRODUCTS_TRANSFERRED';
export const DATA_TEMPLATE_COMPONENT_QUARRY_DETAILS = 'QUARRY_DETAILS';
export const DATA_TEMPLATE_COMPONENT_QUARRY_PLAN_DETAILS = 'QUARRY_PLAN_DETAILS';
export const DATA_TEMPLATE_COMPONENT_RAW_COMMODITY_STOCKPILE = 'RAW_COMMODITY_STOCKPILE';
export const DATA_TEMPLATE_COMPONENT_RAW_MATERIAL_MINED = 'RAW_MATERIAL_MINED';
export const DATA_TEMPLATE_COMPONENT_PERMITS = 'PERMITS';
export const DATA_TEMPLATE_COMPONENT_RAW_MATERIAL_TRANSFERRED = 'RAW_MATERIAL_TRANSFERRED';
export const DATA_TEMPLATE_COMPONENT_SALEABLE_STOCKPILE = 'SALEABLE_STOCKPILE';
export const DATA_TEMPLATE_COMPONENT_SALES = 'SALES';
export const DATA_TEMPLATE_COMPONENT_TENEMENT_STATUS = 'TENEMENT_STATUS';
export const DATA_TEMPLATE_COMPONENT_RAW_COAL_MINED_TRANSFERRED = 'RAW_COAL_MINED_TRANSFERRED';

export const DESCRIPTION_REPORT_GUIDELINE_TEMPLATE = 'Reporting Guideline Template';
export const DESCRIPTION_STATISTICAL_REPORTING_DOCUMENT = 'Statistical Reporting Document';

export const FILE_INVALID_TYPE = 'file-invalid-type';
export const FILE_TOO_LARGE = 'file-too-large';
export const FILE_TOO_SMALL = 'file-too-small';
export const TOO_MANY_FILES = 'too-many-files';

export const RESTRICTED_MIME_TYPES = [
  'application/x-apple-diskimage',
  'application/x-elf',
  'application/x-msdownload',
  'application/x-ms-installer',
  'application/x-sh',
  'text/x-perl',
  'text/x-python',
];

export const RESTRICTED_EXTS = [
  'bat',
  'cer',
  'dll',
  'exe',
  'htm',
  'html',
  'js',
  'msi',
  'nsi',
  'php',
  'py',
  'shtml',
  'xhtml',
];

export const REPORT_TITLE_APPEND_TEXT_PGGD_FORM_01 = '- PGGD-01 Notice of intention to drill a petroleum well or bore - ';
export const REPORT_TITLE_APPEND_TEXT_PGGD_FORM_02 = '- PGGD-02 Notice of [noticeType] of petroleum well or bore - ';
export const REPORT_TITLE_APPEND_TEXT_PGGD_FORM_03 = '- PGGD-03 Notice of intention to carry out hydraulic fracturing activities - ';
export const REPORT_TITLE_APPEND_TEXT_PGGD_FORM_04 = '- PGGD-04 Notice of completion of hydraulic fracturing activities - ';

export const REGEX_SIX_DECIMALS = /^-?(|\d*|\d+\.\d{0,6}|\d*\.\d{1,6}|)$/;
export const REGEX_TWO_DECIMALS = /^-?(|\d*|\d+\.\d{0,2}|\d*\.\d{1,2}|)$/;

export const BOREHOLE_STATUS_EVENT_ABANDONMENT = 'http://linked.data.gov.au/def/borehole-status-event/abandonment';
export const BOREHOLE_STATUS_EVENT_ALTERATION = 'http://linked.data.gov.au/def/borehole-status-event/alteration';
export const BOREHOLE_STATUS_EVENT_COMPLETION = 'http://linked.data.gov.au/def/borehole-status-event/completion';
export const BOREHOLE_STATUS_EVENT_DRILLING_OF_BOREHOLE_ABANDONED = 'http://linked.data.gov.au/def/borehole-status-event/drilling-of-borehole-abandoned';
export const BOREHOLE_STATUS_EVENT_HYDRAULIC_FRACTURING_ABANDONED = 'http://linked.data.gov.au/def/borehole-status-event/hydraulic-fracturing-abandoned';
export const BOREHOLE_STATUS_EVENT_HYDRAULIC_FRACTURING_RELATED_EVENTS = 'http://linked.data.gov.au/def/borehole-status-event/hydraulic-fracturing-related';
export const BOREHOLE_STATUS_EVENT_PETROLEUM_PRODUCTION_COMMENCEMENT = 'http://linked.data.gov.au/def/borehole-status-event/petroleum-production-commencement';
export const DATA_ACCESS_RIGHTS_OPEN = 'http://linked.data.gov.au/def/data-access-rights/open';
export const GEOLOGICAL_SITES_MINE = 'http://linked.data.gov.au/def/geological-sites/mine';
export const GEOLOGICAL_SITES_OPEN_CUT_MINE = 'http://linked.data.gov.au/def/geological-sites/open-cut-mine';
export const GEOLOGICAL_SITES_QUARRY = 'http://linked.data.gov.au/def/geological-sites/quarry';
export const GEOLOGICAL_SITES_QUARRY_GRAVEL = 'http://linked.data.gov.au/def/geological-sites/quarry-gravel';
export const GEOLOGICAL_SITES_QUARRY_HARDROCK = 'http://linked.data.gov.au/def/geological-sites/quarry-hardrock';
export const GEOLOGICAL_SITES_QUARRY_SAND = 'http://linked.data.gov.au/def/geological-sites/quarry-sand';
export const GEOLOGICAL_SITES_QUARRY_SAND_AND_MINE = 'http://linked.data.gov.au/def/geological-sites/quarry-sandgravel';
export const GEOLOGICAL_SITES_UNDERGROUND_MINE = 'http://linked.data.gov.au/def/geological-sites/underground-mine';
export const GEORESOURCE_COAL_QUARTERLY_STATISTICS = 'http://linked.data.gov.au/def/georesource-report/coal-quarterly-statistics';
export const GEORESOURCE_EXTRACTIVES_ANNUAL_STATISTICS = 'http://linked.data.gov.au/def/georesource-report/extractives-annual-statistics';
export const GEORESOURCE_MINERAL_ANNUAL_STATISTICS = 'http://linked.data.gov.au/def/georesource-report/minerals-annual-statistics';
export const GEORESOURCE_REPORT_PGGD_01 = 'http://linked.data.gov.au/def/georesource-report/pggd-01';
export const GEORESOURCE_REPORT_PGGD_02 = 'http://linked.data.gov.au/def/georesource-report/pggd-02';
export const GEORESOURCE_REPORT_PGGD_03 = 'http://linked.data.gov.au/def/georesource-report/pggd-03';
export const GEORESOURCE_REPORT_PGGD_04 = 'http://linked.data.gov.au/def/georesource-report/pggd-04';
export const PERMIT_TYPE_ML = 'http://linked.data.gov.au/def/qld-resource-permit/MiningLease';
export const SITE_STATUS_PROPOSED = 'http://linked.data.gov.au/def/site-status/proposed';
export const VOCABULARY_EARTH_SCIENCE_DATA_CATEGORY = 'https://vocabs.gsq.digital/v/vocab/def:earth-science-data-category';
export const VOCABULARY_GEO_COMMODITIES = 'https://vocabs.gsq.digital/v/vocab/def:geo-commodities';
export const PERMIT_STATUS_ON_PRODUCTION = 'http://linked.data.gov.au/def/site-status/on-production';

export const QGOV_DIGITAL_IDENTIY = 'https://www.qld.gov.au/qdifaq';
export const QGOV_TERMS = 'https://www.qld.gov.au/qditerms';
export const QGOV_PRIVACY = 'https://www.qld.gov.au/qdipriv';

export const LOGIN_PROVIDER_QGOV = 'qGov';
export const LOGIN_PROVIDER_O365 = 'o365';

export const ASSOCIATED_DOCUMENT_LABEL_INCIDENT_REPORT = 'Hydraulic Fracture Incident Report';
export const ASSOCIATED_DOCUMENT_LABEL_ACTUAL_FLUID_COMPOSITION_AND_INCIDENT_REPORT = 'Actual Fluid Composition and Hydraulic Fracture Incident Report';

export const VALIDATION_TYPE_DATE = 'date';
export const VALIDATION_TYPE_STRING = 'string';

export const VALIDATION_RULE_DEPENDENT = 'dependent';
export const VALIDATION_RULE_GROUPING = 'grouping';
export const VALIDATION_RULE_MATCHES = 'matches';
export const VALIDATION_RULE_WITHIN = 'within';
export const VALIDATION_RULE_MAX = 'max';
export const VALIDATION_RULE_MIN = 'min';
export const VALIDATION_RULE_REQUIRED = 'required';
export const VALIDATION_RULE_VOCAB = 'vocab';
export const VALIDATION_RULE_END_OF_QUARTER = 'endOfQuarter';
export const VALIDATION_RULE_END_OF_FINANCIAL_YEAR = 'endOfFY';
export const VALIDATION_RULE_START_OF_MONTH = 'startOfMonth';

export const EXCLUDED_ERROR_TRACE_ID = 'traceId';
export const EXCLUDED_ERROR_TYPE = 'type';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const COUNTRY_AUSTRALIA = 'Australia';

// allow only these field editable for the steps.
export const REPORT_EDITABLE_FIELD_LIST = {
  REPORT_STEP_REPORT_DETAIL: ['title', 'commodity', 'earthScienceData', 'description', 'startDate', 'endDate', 'site', 'permit'],
  REPORT_STEP_DOCUMENT_UPLOAD: ['associatedDocuments', 'reportingTemplateDocuments', 'spatialDocuments', 'statisticalReportingDocuments'],
};

export const VOCAB_TYPEDATA_TEMPLATE_DATA_TEMPLATE = 'DATA_TEMPLATE';
export const VOCAB_TYPEDATA_TEMPLATE_DATA_QUERY = 'QUERY';

export const DISABLE_STEP_NAVIGATION = true;
