import axios from 'axios';
import { currentCredentials, requireSignIn } from './auth';
import sigV4Client from './sigV4Client';

const isHandlerEnabled = (config = {}) => (!(Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled));

const responseSuccessHandler = (response) => new Promise((resolve, reject) => {
  if (isHandlerEnabled(response.config)) {
    if (response.status === 401) {
      reject(new Error('Unauthorized'));
    }
    if (response.status === 403) {
      reject(new Error('Forbidden'));
    }
  }

  resolve(response);
});

const getQueryParams = (url) => {
  const queryParams = {};
  const searchParams = new URLSearchParams(url.search);
  const searchParamKeys = searchParams.keys();

  let searchParam = searchParamKeys.next();
  while (!searchParam.done) {
    queryParams[searchParam.value] = searchParams.get(searchParam.value);
    searchParam = searchParamKeys.next();
  }

  return queryParams;
};

// Calling Amazon API Gateway Authenticated Methods with axios and aws4
// https://medium.com/@joshua.a.kahn/calling-amazon-api-gateway-authenticated-methods-with-axios-and-aws4-6eeda1aa8696
// Connect to API Gateway with IAM Auth
// https://serverless-stack.com/chapters/connect-to-api-gateway-with-iam-auth.html
// https://github.com/AnomalyInnovations/sigV4Client
const requestHandler = (config) => new Promise((resolve, reject) => {
  if (requireSignIn) {
    currentCredentials()
      .then((credentials) => {
        const url = new URL(config.url);
        const unsignedHeaders = {
          Host: url.hostname,
        };
        const queryParams = getQueryParams(url);
        let request = { ...config };
        if (queryParams['X-Amz-Algorithm'] || queryParams.Signature) {
          // no need to sign request, the url already has authorization details
        } else {
          const body = config.data ?? {};
          const signedRequest = sigV4Client
            .newClient({
              accessKey: credentials.data.Credentials.AccessKeyId,
              secretKey: credentials.data.Credentials.SecretKey,
              sessionToken: credentials.data.Credentials.SessionToken,
              region: credentials.cognito.config.region,
              endpoint: url.origin,
            })
            .signRequest({
              method: config.method,
              path: url.pathname,
              headers: unsignedHeaders,
              queryParams,
              body,
            });
          request = {
            ...request,
            headers: signedRequest.headers,
          };
        }
        resolve(request);
      })
      .catch((error) => {
        reject(error);
      });
  } else {
    resolve(config);
  }
});

/*
 * This function sets config settings for all axios calls in the project.
*/
export const axiosSetup = () => {
  axios.interceptors.response.use(
    (response) => Promise.resolve(responseSuccessHandler(response)),
    (error) => Promise.reject(error),
  );

  axios.interceptors.request.use(
    (request) => Promise.resolve(requestHandler(request)),
    (error) => Promise.reject(error),
  );
};
